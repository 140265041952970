import React from "react"
import { Link, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Social from "../../components/social.js"
import Layout from "../../components/LayoutAlt.js"
import { FaArrowAltCircleLeft } from "react-icons/fa"

export default function TheWord ({ data }) {
  
    return(
   
        <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="The Word of God. The only Truth for this time." />
        <title>ReaCH | The Word</title>
        <link rel="canonical" href="https://reachun.org/biblestudy/theword" />
      </Helmet>

          <div className="sm:w-3/4 sm:ml-1/8 mt-5 sm:mt-10 xs:p-4 sm:p-0"> 
          <section className="font-montserrat">
            <h1 className="font-bold text-3xl sm:text-5xl xl:text-2xl text-blue-dark text-center xl:text-left">The Word</h1>
            <p className="font-light text-xl text-blue-dark mt-2 tracking-wider text-justify"> For the word of God is quick, and powerful, and sharper than any twoedged sword,<br/> piercing even to the dividing asunder of soul and spirit, and of the joints and marrow, <br/> and is a discerner of the thoughts and intents of the heart. <span className="font-bold">- Hebrews 4:12</span></p>
          </section>

              <Link to="/biblestudy">
                <section className="font-opensans  text-white  bg-orange-dark my-10 py-1 pl-4 
                rounded-r-xl flex items-center">
                   <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer"/> 
                   <p> biblestudy / theword</p>
                </section>
              </Link>

             <div className="grid xs:grid-cols-1 sm:grid-cols-2 xs:gap-2 lg:gap-8 xl:grid-cols-3 xl:gap-4 2xl:grid-cols-4 mb-10">
              {data.allStrapiBiblestudies.edges.map(post => (
                 
                    <div key={post.node.id} className=" rounded-lg xs:h-104 sm:h-104 shadow hover:shadow-xl transform hover:-translate-y-1 transition duration-700 ease-in-out">
                      <img src={post.node.src_image} 
                           srcSet={post.node.imgix_path} 
                           alt={post.node.title} className="rounded-t-lg w-full h-64 object-cover object-bottom "/>

                      <p className="pl-2 font-opensans font-bold text-blue-dark text-xs mt-4">{post.node.date}</p>
                      <h1 className="pl-2 font-montserrat font-bold text-xl 3xl:text-2xl text-gray-700 mt-4">{post.node.title}</h1>
                      <p className="xs:px-2 sm:px-0 sm:pl-2 sm:w-4/5 text-sm 3xl:text-base text-gray-700 mt-2">{post.node.description}</p>
                      <Link to={`${post.node.slug}`}><p className="pl-2 text-xs font-opensans text-orange-light mt-5">Read More</p></Link>
                    </div>
                 
                ))}
             </div>
          </div>
          <Social />
        </Layout>   
  )

}

export const query = graphql`
  query{
    allStrapiBiblestudies(filter: {category: {eq: "theword"}}, sort: {fields: date, order: DESC}) {
      edges {
        node {
          word
          prophecy
          channel
          id
          slug
          title
          description
          date(formatString: "LL")
          imgix_path
          src_image
      }
    }
    }
  }
`